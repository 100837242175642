module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5DQRKX4","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@workwithpact/pact-contentful-schema-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://7b68b66924d24efb99022f1440910740@o267635.ingest.sentry.io/6777288","environment":"production","enabled":"https://7b68b66924d24efb99022f1440910740@o267635.ingest.sentry.io/6777288","denyUrls":[{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"feQTHqEx6JfsN5J39jdcOt6sBG4MmN68","devKey":"feQTHqEx6JfsN5J39jdcOt6sBG4MmN68","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-autonomy/gatsby-browser.js'),
      options: {"plugins":[],"contentfulConfig":{"spaceId":"a0wu6jp9lwnj","accessToken":"mRGjsTuqcePS0sM5a6MMKnus2MEHZJBJZESot7Lz4Yw","host":"cdn.contentful.com","environment":"master"},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/build/repo/src/intl"},"sentryDSN":"https://7b68b66924d24efb99022f1440910740@o267635.ingest.sentry.io/6777288","siteMetadata":{"title":"Commons","siteUrl":"https://www.commons.co","description":"We're here to improve your health so you can get the most out of every day.","social":{"facebook":"commons_co","instagram":"commons_co","pinterest":"commons_co"}},"authConfig":{"loadUserDuringInitialization":true,"stytch":{"apiKey":"public-token-live-7f28f956-acf4-462f-9823-23c081cd27c8","redirectPath":"/authenticate","sessionDurationMinutes":129600}},"segmentConfig":{"prodKey":"feQTHqEx6JfsN5J39jdcOt6sBG4MmN68","devKey":"feQTHqEx6JfsN5J39jdcOt6sBG4MmN68","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    }]
