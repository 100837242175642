exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-authenticate-tsx": () => import("./../../../src/pages/authenticate.tsx" /* webpackChunkName: "component---src-pages-authenticate-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-guided-protocols-tsx": () => import("./../../../src/pages/guided-protocols.tsx" /* webpackChunkName: "component---src-pages-guided-protocols-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-tsx": () => import("./../../../src/pages/join.tsx" /* webpackChunkName: "component---src-pages-join-tsx" */),
  "component---src-pages-learn-tsx": () => import("./../../../src/pages/learn.tsx" /* webpackChunkName: "component---src-pages-learn-tsx" */),
  "component---src-pages-order-confirmation-tsx": () => import("./../../../src/pages/order/confirmation.tsx" /* webpackChunkName: "component---src-pages-order-confirmation-tsx" */),
  "component---src-pages-our-story-tsx": () => import("./../../../src/pages/our-story.tsx" /* webpackChunkName: "component---src-pages-our-story-tsx" */),
  "component---src-pages-styleguide-tsx": () => import("./../../../src/pages/styleguide.tsx" /* webpackChunkName: "component---src-pages-styleguide-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */),
  "component---src-templates-ambassador-event-tsx": () => import("./../../../src/templates/ambassador-event.tsx" /* webpackChunkName: "component---src-templates-ambassador-event-tsx" */),
  "component---src-templates-ambassador-news-tsx": () => import("./../../../src/templates/ambassador-news.tsx" /* webpackChunkName: "component---src-templates-ambassador-news-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-kit-tsx": () => import("./../../../src/templates/kit.tsx" /* webpackChunkName: "component---src-templates-kit-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-protocol-tsx": () => import("./../../../src/templates/protocol.tsx" /* webpackChunkName: "component---src-templates-protocol-tsx" */),
  "component---src-templates-shop-tsx": () => import("./../../../src/templates/shop.tsx" /* webpackChunkName: "component---src-templates-shop-tsx" */),
  "component---src-templates-static-page-tsx": () => import("./../../../src/templates/static-page.tsx" /* webpackChunkName: "component---src-templates-static-page-tsx" */)
}

