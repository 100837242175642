import React, { FC, Fragment, PropsWithChildren } from 'react';
import { Link } from 'gatsby';
import { ConsentManager } from '@segment/consent-manager';
import inEU from '@segment/in-eu';

const bannerContent = (
  <span>
    We use cookies (and other similar technologies) to collect data to improve
    your experience on our site.
    <br />
    By using our website, you’re agreeing to the collection of data as described
    in our <Link to="/privacy-policy">Website Data Collection Policy</Link>.
  </span>
);

const BannerActionsBlock: React.FC<BannerActionsBlockProps> = ({
  acceptAll,
  denyAll,
}) => (
  <div>
    <button type="button" onClick={acceptAll}>
      Allow all
    </button>
    <button type="button" onClick={denyAll}>
      Deny all
    </button>
  </div>
);

interface BannerActionsBlockProps {
  acceptAll: () => void;
  denyAll: () => void;
}

const preferencesDialogContent = (
  <div>
    <p>
      We use data collected by cookies and JavaScript libraries to improve your
      browsing experience, analyze site traffic, deliver personalized
      advertisements, and increase the overall performance of our site.
    </p>
    <p>
      By using our website, you’re agreeing to our{' '}
      <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
        Website Data Collection Policy
      </a>
      .
    </p>
    <p>
      The table below outlines how we use this data by category. To opt out of a
      category of data collection, select “No” and save your preferences.
    </p>
  </div>
);

const cancelDialogContent = (
  <div>
    Your preferences have not been saved. By continuing to use our website,
    you’re agreeing to our{' '}
    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
      Website Data Collection Policy
    </a>
    .
  </div>
);

const getSegmentWriteKey = () => {
  return process.env.NODE_ENV === 'production' &&
    process.env.CONTEXT === 'production'
    ? process.env.GATSBY_SEGMENT_PRODUCTION_WRITE_KEY
    : process.env.GATSBY_SEGMENT_DEV_WRITE_KEY;
};

const onError = (error: any) => {
  console.log('ConsentManagerBanner error', error);
};

const shouldRequireConsent = () => {
  if (process.env.GATSBY_SEGMENT_SHOULD_REQUIRE_CONSENT === 'true') {
    return true;
  }

  return inEU();
};

/**
 * This file integrates Segment's consent-manager to protect our visitors privacy.
 *
 * See https://github.com/segmentio/consent-manager
 */
const ConsentManagerWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
  const writeKey = getSegmentWriteKey();
  return (
    <Fragment>
      {children}

      <div>
        <ConsentManager
          writeKey={writeKey}
          shouldRequireConsent={shouldRequireConsent}
          implyConsentOnInteraction={false}
          bannerContent={bannerContent}
          bannerSubContent="You can manage your preferences here!"
          bannerActionsBlock={BannerActionsBlock}
          bannerHideCloseButton={false}
          bannerAsModal={false}
          bannerTextColor="#FFFFFF"
          bannerBackgroundColor="#38A169"
          preferencesDialogTitle="Website Data Collection Preferences"
          preferencesDialogContent={preferencesDialogContent}
          cancelDialogTitle="Are you sure you want to cancel?"
          cancelDialogContent={cancelDialogContent}
          onError={onError}
        />
      </div>
    </Fragment>
  );
};

export default ConsentManagerWrapper;
