import React from 'react';
import ConsentManagerWrapper from './src/components/Segment/ConsentManager';
import AuthProvider from './src/components/Auth/AuthProvider';
import axiosClient from '@chordcommerce/gatsby-theme-autonomy/src/services/api/axios-client';
import './static/fonts/fonts.css';
import { storage } from '@chordcommerce/gatsby-theme-autonomy';
import { logSentryError } from '@chordcommerce/gatsby-theme-autonomy';

/**
 * Wraps the apps root element with Segment's Consent Manager.
 *
 * See:
 * https://github.com/segmentio/consent-manager
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 * https://github.com/segmentio/consent-manager/issues/10#issuecomment-679896358
 */
export const wrapPageElement = ({ element }: { element: any }) => {
  return (
    <ConsentManagerWrapper>
      <AuthProvider>{element}</AuthProvider>
    </ConsentManagerWrapper>
  );
};

/* Hijack Axios Client to _not_ pass the token to the server when an ambassador is building a cart for someone else */
axiosClient.interceptors.request.use(config => {
  if (
    false && 
    window?.location?.pathname?.includes?.('/account/ambassador') &&
    config?.url?.includes('/api/orders') &&
    config?.headers?.Authorization
  ) {
    config.headers.Authorization = '';
    console.log('Stripping the original token from the request', config);
  }
  if (
    false && 
    config.url?.includes?.('associate_user') &&
    window?.location?.pathname?.includes?.('/account/ambassador')
  ) {
    const error = new Error(
      'Not allowed to associate user, as this is an ambassador-created cart'
    );
    (error as any).config = config;
    throw error;
  }
  return config;
});

// This is a hack to get around the fact that the Chord SDK doesn't share card error details when the card is declined, but instead just returns a generic error message.
axiosClient.interceptors.response.use(
  response => {
    const config = response.config;
    if (
      false && 
      window?.location?.pathname?.includes?.('/account/ambassador') &&
      config?.url?.includes('/api/orders') &&
      response?.data?.id
    ) {
      response.data.user_id = -100;
      console.log('Stripping the original user_id from the response', response);
    }
    return response;
  },
  async error => {
    const errorMessages = {
      CVV_FAILURE: 'The CVV code you entered is incorrect. Please try again.',
      ADDRESS_VERIFICATION_FAILURE:
        'The zip you entered does not match the address on file for your card. Please try again.',
      INVALID_EXPIRATION:
        'The expiration date you entered is invalid. Please try again.',
      GENERIC_DECLINE: 'Your card was declined. Please try again.',
    };

    if (
      error?.response?.data?.error?.indexOf?.(
        'The order could not be transitioned.'
      ) !== -1
    ) {
      const extraErrorDetails = `${
        error?.response?.data?.errors?.base?.[0] || ''
      }`;
      const matchingErrorKey = Object.keys(errorMessages).find(
        key => extraErrorDetails.indexOf(key) !== -1
      );
      const errorMessage = matchingErrorKey
        ? errorMessages[matchingErrorKey]
        : error?.response?.data?.error;
      error.response.data.error = errorMessage;
      error.config =
        error.config || error.request?.config || error.response?.config;
    }
    return Promise.reject(error);
  }
);
(axiosClient as any)?.interceptors?.response?.handlers?.reverse();

/* Similarly, hijack storage methods so we can have different carts when we are an ambassador building a cart for a client */
const originalStorageMethods = {
  getItem: storage.getItem,
  setItem: storage.setItem,
  removeItem: storage.removeItem,
};

for (const method in originalStorageMethods) {
  const originalMethod = originalStorageMethods[method];
  storage[method] = (...args) => {
    const isAmbassadorPortal = false && window?.location?.pathname?.includes?.(
      '/account/ambassador'
    );
    const originalKey = args.shift();
    const key =
      isAmbassadorPortal &&
      originalKey?.includes?.('cart') &&
      !originalKey?.includes?.('ambassador|')
        ? `ambassador|${originalKey}`
        : originalKey;
    try {
      return originalMethod(...[key, ...args]);
    } catch (error) {
      console.error(error);
    }
  };
}

const hashParams = new URLSearchParams(window.location.hash.substring(1));
if (hashParams.has('number') && hashParams.has('token')) {
  storage.setItem('cart-number', hashParams.get('number'));
  storage.setItem('cart-token', hashParams.get('token'));
  window.location.hash = '';
}

export const onClientEntry = () => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    const { analytics, location } = window as any;

    analytics.addSourceMiddleware(({ payload, next }) => {
      let currentUser: any = null;
      try {
        currentUser = JSON.parse(localStorage.getItem('chord-user') || 'null');
      } catch (error) {
        console.error(error);
      }

      if (payload && payload.obj) {
        const extraPayload = currentUser?.data
          ? {
              chord_user_id: currentUser.data.id,
              chord_user_email: currentUser.data.email,
              chord_user_roles: currentUser.data.roles,
              chord_user_roles_str: (currentUser.data.roles || []).join(', '),
              mentor_id: currentUser.data?.metadata?.ambassadorId || undefined,
            }
          : {};
        payload.obj.properties = {
          ...(payload.obj.properties || {}),
          ...extraPayload,
        };
        payload.obj.traits = { ...(payload.obj.traits || {}), ...extraPayload };
      }
      // console.log('payload', payload);
      try {
        next(payload);
      } catch (error) {
        next(payload);
        logSentryError(error, { source: 'addSourceMiddleware' });
      }
    });
  } catch (error) {
    logSentryError(error, { source: 'addSourceMiddleware' });
  }
};
